export default {
  amber: "#ffc107",
  darkAmber: "#e6ae04",
  orange: "#ffa000",
  blue: "#2979ff",
  dodgerBlue: "#1E84E2",
  darkBlue: "#225c92",
  white: "#ffffff",
  darkGrey: "#737373",
  lightGrey: "#8c8a8ab8",
  solitude: "#e2e4e6",
  black: "#000000",
  nightRider: "#333333",
  lightRed: "#f35e5e",
  red: "#ff0000",
  forestGreen: "#20ab0f",
  lightGreen: "#1cfd00",
  green: "#64dd17",
  layoutColor: "#3c3c3c",
  receiveAllButtonColor: "#e57373",
  receiveAllButtonHoverColor: "#de6262",
  serveAllButtonColor: "#28cacc",
  serveAllButtonHoverColor: "#2eb5b7",
  dismissButtonNewOrder: "#0b4373",
  dismissButtonCancel: "#7b510e",
  orderNotesCard: "#fff176",
};
