import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import colors from "../../../theme/colors";

const useStyles = makeStyles(() => ({
  form: {
    width: "100%",
    padding: "10px",
  },
  textField: {
    flexBasis: 200,
    width: "100%",
    paddingBottom: "30px",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    width: "100%",
    color: theme.palette.getContrastText(colors.dodgerBlue),
    backgroundColor: colors.dodgerBlue,
    "&:hover": {
      backgroundColor: colors.darkBlue,
    },
  },
}))(Button);

const LoginForm = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [location, setLocation] = useState();
  const [password, setPassword] = useState();

  const classes = useStyles();

  const login = {
    type: "kitchen",
    location_code: location,
    password,
  };

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        return handleSubmit(login);
      }}
    >
      <Grid item xs={12}>
        <TextField
          id="location_code"
          className={classes.textField}
          variant="outlined"
          label={t("login.Form.LocationCode")}
          required
          onChange={(e) => setLocation(e.target.value)}
        />
        <TextField
          id="password"
          className={classes.textField}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          label={t("login.Form.TerminalPassword")}
          required
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ColorButton
          variant="contained"
          type="submit"
          size="large"
          className={classes.button}
        >
          {t("login.Form.Login")}
        </ColorButton>
      </Grid>
    </form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
