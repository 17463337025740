import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import axios from "axios";
import preval from "preval.macro";
import NoSleep from "nosleep.js";
import { ApolloProvider, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { SnackbarProvider, useSnackbar } from "notistack";
import { styled as muiStyled } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import colors from "./theme/colors";
import GlobalStyle from "./theme/globalStyles";
import client from "./client";

import { Provider as KitchenServiceProvider } from "./kitchen-service";

import App from "./App";
import Login from "./pages/auth/Login";

import "./i18n";

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

const noSleep = new NoSleep();
document.addEventListener("click", function enableNoSleep() {
  document.removeEventListener("click", enableNoSleep, false);
  noSleep.enable();
}, false);

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("terminal_token");
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = token;
    return config;
  },
  (error) => Promise.reject(error),
);

const buildTime = preval`module.exports = new Date().toLocaleString([], {timeZoneName: "short"});`;
console.log(`Build time: ${buildTime}`);

const CONF_QUERY = gql`
  query TerminalQuery @hasura {
    locations {
      id
      name
    }
    terminals {
      id
      name
      type
      settings
    }
  }
`;

const Loader = muiStyled(CircularProgress)({
  position: "fixed",
  top: "50%",
  left: "50%",
  color: colors.dodgerBlue,
});

const AuthRoot = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [getTerminalConf, { loading, data, error }] = useLazyQuery(CONF_QUERY, {
    fetchPolicy: "network-only",
  });
  if (error) {
    localStorage.removeItem("terminal_token");
    enqueueSnackbar(error.message, { variant: "error" });
  }

  const handleSubmit = (values) => {
    axios
      .post("auth/terminal/login", values)
      .then((res) => {
        localStorage.setItem("terminal_token", res.data.token);
        getTerminalConf();
      })
      .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
  };

  if (loading) return <Loader />;

  const token = localStorage.getItem("terminal_token");
  if (token && !data) getTerminalConf();

  return data ? (
    <KitchenServiceProvider config={{ location: data.locations[0] }}>
      <App location={data.locations[0]} terminal={data.terminals[0]} />
    </KitchenServiceProvider>
  ) : (
    <Login handleSubmit={handleSubmit} />
  );
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <GlobalStyle />
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      preventDuplicate
    >
      <AuthRoot />
    </SnackbarProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);
