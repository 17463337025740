import { useCallback } from "react";
import { useServiceState } from "./index";

const useAction = () => {
  const { conn } = useServiceState();

  const sendAction = useCallback((action, payload) => new Promise((resolve, reject) => {
    if (!conn) {
      reject(new Error("Не удалось соединиться с терминалом. Убедитесь что терминал включен и находиться онлайн."));
      return;
    }
    const actionId = `action-${Date.now().toString()}`;
    const listener = ({ actionId: id, status, message }) => {
      if (id === actionId) {
        conn.off("data", listener);
        if (status === "ok") {
          resolve();
        } else {
          reject(new Error(message));
        }
      }
    };

    conn.on("data", listener);
    conn.send({ actionId, action, payload });
  }), [conn]);

  return { sendAction };
};

export default useAction;
