import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { makeStyles } from "@material-ui/core/styles";

import useAction from "../../../kitchen-service/useAction";

import colors from "../../../theme/colors";
import Timer from "./Timer";
import OrderLineCard from "./OrderLineCard";
import i18n from "../../../i18n";
import OrderNotesCard from "./OrderNotesCard";

const Card = styled.div`
  > * {
    background-color: ${colors.solitude};
    color: ${colors.black};
    padding: 0 10px;
  }

  .header {
    padding: 10px 10px;
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

const OrderLinesContainer = styled.div`
  list-style: none;
  margin: 0;
  margin-left: 8px;
  margin-right: 8px;
  width: calc(100% - 36px);
  max-height: calc(
    100% - 88px - ${(props) => (props.hasFooterButton ? "70px" : "10px")}
  );
  overflow-y: auto;
`;

const Footer = styled(Grid)`
  &&& {
    padding-bottom: 10px;
    width: calc(100% - 36px);
    margin-left: 8px;
    margin-right: 8px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const useStyles = makeStyles({
  footerButton: {
    marginTop: "10px",
    width: "100%",
    height: "50px",
    fontFamily: "sans-serif",
    fontSize: "19px",
    fontWeight: 600,
  },
  startAllButton: {
    backgroundColor: "#fffb3b",
    "&:hover": {
      backgroundColor: "#b1ad00",
    },
  },
  readyAllButton: {
    backgroundColor: "#03ff0e",
    "&:hover": {
      backgroundColor: "#00b408",
    },
  },
  serveAllButton: {
    backgroundColor: colors.serveAllButtonColor,
    "&:hover": {
      backgroundColor: colors.serveAllButtonHoverColor,
    },
  },
});

const ORDER_TYPE_LABELS = {
  in_store: i18n.t("const:order_type.in_store"),
  pickup: i18n.t("const:order_type.pickup"),
  delivery: i18n.t("const:order_type.delivery"),
};

const OrderCard = ({ onStart, order, type, style }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { sendAction } = useAction();

  const orderLineIds = order.order_lines
    .filter((orderLine) => orderLine.status !== "cancelled")
    .map((orderLine) => orderLine.id);

  const showServeAllButton = type === "distribution" && orderLineIds.length > 1
    && order.order_lines.every(
      (orderLine) => ["ready", "served", "cancelled"].includes(orderLine.status),
    );

  const showStartAllButton = type === "kitchen" && orderLineIds.length > 1
    && order.order_lines.some((orderLine) => orderLine.status === "received");

  const showReadyAllButton = type === "kitchen" && orderLineIds.length > 1
    && !showStartAllButton
    && order.order_lines.some((orderLine) => orderLine.status === "processing");

  const hasFooterButton = showServeAllButton || showStartAllButton || showReadyAllButton;

  const handleStatusUpdate = (lineIds, newStatus) => sendAction("changeOrderLinesStatus", {
    orderId: order.id, orderLineIds: lineIds, newStatus,
  })
    .then(() => newStatus === "processing" && onStart?.())
    .catch((error) => enqueueSnackbar(error.message, { variant: "error" }));

  return (
    <Card style={style}>
      <Grid container alignItems="center" className="header">
        <Grid item xs={6}>
          <Typography variant="h4">{order.display_number}</Typography>
        </Grid>
        <Grid item xs={6} container direction="column" alignItems="flex-end">
          <Typography>
            <Timer
              start={order.time}
              color={(duration) => (duration >= 15 * 60 ? "red" : "black")}
            />
          </Typography>
          <Typography>
            {order.list_table_name || ORDER_TYPE_LABELS[order.type]}
          </Typography>
        </Grid>
      </Grid>

      <OrderLinesContainer hasFooterButton={hasFooterButton}>
        {order.notes && <OrderNotesCard>{order.notes}</OrderNotesCard>}
        {order.order_lines.map((orderLine) => (
          <OrderLineCard
            type={type}
            key={`${orderLine.id}-${orderLine.status}`}
            orderLine={orderLine}
            orderType={order.type}
            onStatusUpdate={(newStatus) =>
              handleStatusUpdate([orderLine.id], newStatus)}
          />
        ))}
      </OrderLinesContainer>

      <Footer>
        {showServeAllButton && (
          <Button
            variant="contained"
            className={`${classes.footerButton} ${classes.serveAllButton}`}
            onClick={() => handleStatusUpdate(orderLineIds, "served")}
            startIcon={order.type === "delivery" ? <LocalShippingIcon /> : <DoneAllIcon />}
          >
            {order.type === "delivery"
              ? t("orders.OrderCard.OnDelivery") : t("orders.OrderCard.ServeOrder")}
          </Button>
        )}
        {showStartAllButton && (
          <Button
            variant="contained"
            className={`${classes.footerButton} ${classes.startAllButton}`}
            onClick={() => handleStatusUpdate(orderLineIds, "processing")}
            startIcon={<FlashOnIcon />}
          >
            {t("orders.OrderCard.StartAll")}
          </Button>
        )}
        {showReadyAllButton && (
          <Button
            variant="contained"
            className={`${classes.footerButton} ${classes.readyAllButton}`}
            onClick={() => handleStatusUpdate(orderLineIds, "ready")}
            startIcon={<DoneAllIcon />}
          >
            {t("orders.OrderCard.ReadyAll")}
          </Button>
        )}
      </Footer>
    </Card>
  );
};

OrderCard.propTypes = {
  onStart: PropTypes.func,
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_number: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    status_updated_at: PropTypes.string.isRequired,
    list_table_name: PropTypes.string,
    order_lines: PropTypes.array.isRequired,
    notes: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

export default OrderCard;
