import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { makeStyles } from "@material-ui/core/styles";

import colors from "../../theme/colors";

import LoginForm from "./components/LoginForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  image: {
    backgroundImage: "url(\"https://source.unsplash.com/random?food\")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    borderRadius: "0px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2, 6),
    width: "100%",
  },
  avatar: {
    color: colors.white,
    backgroundColor: colors.red,
  },
  title: {
    fontSize: "26px",
    fontWeight: 500,
    padding: "10px",
    fontFamily: "sans-serif",
  },
}));

const Login = ({ handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        className={classes.paper}
      >
        <div className={classes.formContainer}>
          <Avatar className={classes.avatar}>
            <LockOpenIcon />
          </Avatar>
          <span className={classes.title}>{t("login.Entrance")}</span>
          <LoginForm handleSubmit={handleSubmit} />
        </div>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default Login;
