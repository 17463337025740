import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import colors from "theme/colors";

const Container = styled(Card)`
  background-color: ${colors.orderNotesCard};
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 16px;
`;

const OrderNotesCard = ({ children }) => (
  <Container>
    <Typography><i>{children}</i></Typography>
  </Container>
);

OrderNotesCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default OrderNotesCard;
