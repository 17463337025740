import { ApolloClient } from "@apollo/client/core";
import { ApolloLink, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { InMemoryCache } from "@apollo/client/cache";

const hasuraClient = new HttpLink({
  uri: "/v1/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("terminal_token");
  return {
    headers: token ? { ...headers, authorization: token } : headers,
  };
});

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors && graphQLErrors[0].message.includes("unauthorized")) window.location.reload();
    if (networkError && networkError.statusCode === 401) window.location.reload();
  }),
  authLink.concat(hasuraClient),
]);

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
