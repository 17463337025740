import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { FixedSizeList } from "react-window";

import Button from "@material-ui/core/Button";

import useOrders from "kitchen-service/useOrders";

import sound from "sound";

import OrderCard from "./components/OrderCard";

const Container = styled.div`
  padding-top: 20px;
  height: calc(100% - 84px);
`;

let lastOrderId = "initial";
let lastCancelledAt = "0";
let processingOrderLineIds = [];
let isAlertPlaying = false;

const Orders = ({ type, notificationEnabled }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = (key) => {
    sound.pause();
    isAlertPlaying = false;
    closeSnackbar(key);
  };

  const openOrders = useOrders({
    onMessage: (data) => {
      // eslint-disable-next-line react/no-danger
      enqueueSnackbar(<span dangerouslySetInnerHTML={{ __html: data.message }} />, {
        variant: data.type,
        autoHideDuration: data.duration * 1000 || null,
        action: (key) => (
          <Button onClick={() => handleCloseSnackbar(key)}>
            OK
          </Button>
        ),
      });
      if (data.sound && !isAlertPlaying) {
        sound.loop(!data.duration);
        sound.play(data.sound);
      }
    },
  });

  const [widthFixedSizeList, setWidthFixedSizeList] = useState(
    window.innerWidth,
  );

  const handleResize = () => setWidthFixedSizeList(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const currentLastOrderId = openOrders.length > 0
    ? openOrders[openOrders.length - 1].id : null;

  /* eslint-disable no-param-reassign */
  if (notificationEnabled) {
    const currentProcessingOrderLineIds = _(openOrders)
      .map("order_lines")
      .flatten()
      .filter({ status: "processing" })
      .map("id")
      .sort()
      .value();

    if (lastOrderId !== "initial" && currentLastOrderId > lastOrderId) {
      if (currentProcessingOrderLineIds.length > 0 || type === "distribution") {
        if (!isAlertPlaying) {
          sound.loop(false);
          sound.play("newOrderSound");
        }
      } else {
        enqueueSnackbar(`Добавлен новый заказ ${_.last(openOrders).display_number}`, {
          persist: true,
          variant: "info",
          action: (key) => (<Button onClick={() => handleCloseSnackbar(key)}>OK</Button>),
        });
        isAlertPlaying = true;
        sound.loop(true);
        sound.play("alertNewOrderSound");
      }
    }

    const currentLastCancelledOrderLine = _(openOrders)
      .map(({ id, order_lines }) => order_lines.map((ol) => ({ ...ol, order_id: id })))
      .flatten()
      .filter({ status: "cancelled" })
      .sortBy("status_updated_at")
      .last();

    if (
      lastOrderId !== "initial"
      && currentLastCancelledOrderLine
      && currentLastCancelledOrderLine.status_updated_at > lastCancelledAt
    ) {
      const order = openOrders.find(
        (openOrder) => openOrder.id === currentLastCancelledOrderLine.order_id,
      );
      if (processingOrderLineIds.includes(currentLastCancelledOrderLine.id)) {
        enqueueSnackbar(`В заказе ${order.display_number} ${currentLastCancelledOrderLine.display_name} отменен`, {
          persist: true,
          variant: "error",
          action: (key) => (<Button onClick={() => handleCloseSnackbar(key)}>OK</Button>),
        });
        isAlertPlaying = true;
        sound.loop(true);
        sound.play("cancelOrderSound");
      } else if (!isAlertPlaying) {
        sound.loop(false);
        sound.play("cancelOrderSound");
      }
    }

    lastCancelledAt = currentLastCancelledOrderLine
      && currentLastCancelledOrderLine.status_updated_at;
    processingOrderLineIds = currentProcessingOrderLineIds;
  }

  if (currentLastOrderId || lastOrderId === "initial") {
    lastOrderId = currentLastOrderId;
  }

  return (
    <Container>
      <FixedSizeList
        layout="horizontal"
        height="100%"
        width={widthFixedSizeList}
        itemSize={360}
        overscanCount={3}
        itemCount={openOrders.length}
        itemData={openOrders}
        itemKey={(idx, data) => data[idx].id}
      >
        {({ data, index, style }) => (
          <OrderCard
            onStart={() => handleCloseSnackbar()}
            type={type}
            style={style}
            order={data[index]}
          />
        )}
      </FixedSizeList>
    </Container>
  );
};

Orders.propTypes = {
  type: PropTypes.string.isRequired,
  notificationEnabled: PropTypes.bool.isRequired,
};

export default Orders;
