import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Timer from "./Timer";
import {
  NEXT_ORDER_STATUS,
  ORDER_LINE_COLORS,
  ORDER_LINE_HOVER_COLORS,
  ORDER_STATUS_ACTION_LABELS,
  ORDER_STATUS_ACTION_ICONS,
  DISTRIBUTION_ORDER_STATUS_ACTION_ICONS,
  DISTRIBUTION_ORDER_STATUS_ACTION_LABELS,
  ORDER_STATUS_ACTION_DISABLED,
  DISTRIBUTION_ORDER_STATUS_ACTION_DISABLED,
} from "../constants";
import colors from "../../../theme/colors";

export const stringifyModifiers = (items) => {
  const grouped = _.groupBy(items.map((i) => i.list_name));
  return Object.keys(grouped)
    .map((name) => {
      const count = grouped[name].length;
      return count > 1 ? `${name}\u00A0(x${count})` : name;
    })
    .join(", ");
};

const StyledCard = styled(Card)`
  margin-bottom: 2px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .card-actions-area {
    padding: 10px 15px;
  }

  .quantity {
    padding: 0px 2px;
    line-height: 24px;
    font-weight: 700;
  }

  .menu-item {
    padding: 0px 6px;
    line-height: 24px;
  }

  .modifiers {
    padding: 4px 10px 0px;
    line-height: 1;
  }

  .notes {
    font-style: italic;
    padding: 4px 10px 0px;
    line-height: 1;
  }

  .card-actions {
    padding: 0px;
  }
`;

const StatusButton = styled(Button)`
  &&& {
    margin: 0px;
    width: ${(props) => props.width};
    height: 50px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background: ${(props) => props.background};
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 600;

    :hover {
      background: ${(props) => props.hover};
    }
  }
`;

const TimerContainer = styled.div`
  background: ${colors.lightGrey};
  height: 50px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 600;
`;

const OrderLineCard = ({ orderLine, onStatusUpdate, type, orderType }) => {
  const nextStatus = NEXT_ORDER_STATUS[orderLine.status];
  const Icon = type === "distribution"
    ? DISTRIBUTION_ORDER_STATUS_ACTION_ICONS[nextStatus]
    : ORDER_STATUS_ACTION_ICONS[nextStatus];
  const hideActionButton = orderType === "delivery" && type === "distribution";

  return (
    <StyledCard>
      <CardActionArea className="card-actions-area">
        <Grid container direction="row" wrap="nowrap">
          <Typography variant="h6" className="quantity">
            {orderLine.count}&times;
          </Typography>
          <div>
            <Typography variant="h6" className="menu-item">
              {orderLine.display_name}
            </Typography>
          </div>
        </Grid>
        {orderLine.modifiers?.filter((m) => m.value.length > 0)
          .map((modifier) => (
            <Typography className="modifiers">
              {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
            </Typography>
          ))}
        {orderLine.notes && (
          <div>
            <Typography className="notes">
              {orderLine.notes}
            </Typography>
          </div>
        )}
      </CardActionArea>

      {hideActionButton || (
        <CardActions className="card-actions">
          {orderLine.status !== "served" && (
            <TimerContainer>
              <Timer
                start={orderLine.status_updated_at}
                color={(duration) => (duration >= 15 * 60 ? "red" : "white")}
              />
            </TimerContainer>
          )}
          <StatusButton
            width={orderLine.status === "served" ? "100%" : "70%"}
            background={ORDER_LINE_COLORS[nextStatus]}
            hover={ORDER_LINE_HOVER_COLORS[nextStatus]}
            onClick={() => onStatusUpdate(nextStatus)}
            startIcon={Icon && <Icon />}
            disabled={
              type === "distribution"
                ? DISTRIBUTION_ORDER_STATUS_ACTION_DISABLED[nextStatus]
                : ORDER_STATUS_ACTION_DISABLED[nextStatus]
            }
          >
            {type === "distribution"
              ? DISTRIBUTION_ORDER_STATUS_ACTION_LABELS[nextStatus]
              : ORDER_STATUS_ACTION_LABELS[nextStatus]}
          </StatusButton>
        </CardActions>
      )}
    </StyledCard>
  );
};

OrderLineCard.propTypes = {
  orderLine: PropTypes.object.isRequired,
  onStatusUpdate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
};

export default OrderLineCard;
