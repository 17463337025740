import { useEffect, useState } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import { useServiceState } from "./index";

const useOrders = (options = {}) => {
  const { conn } = useServiceState();
  const [orders, setOrders] = useState([]);
  const [settings] = useLocalStorage("settings", {
    type: "kitchen", workshopIds: null,
  });

  useEffect(() => {
    if (!conn) return () => {};
    const subscriptionId = `sub-${Date.now().toString()}`;

    const listener = ({ id, data }) => {
      if (id === subscriptionId) {
        setOrders(data);
      } else if (id === `${subscriptionId}:messages`) {
        options.onMessage?.(data);
      }
    };

    conn.on("data", listener);
    conn.send({
      action: "subscribe",
      id: subscriptionId,
      filters: {
        types: [settings.type],
        orderStatus: settings.type === "kitchen"
          ? ["received", "processing", "ready", "on_delivery", "served"]
          : ["received", "processing", "ready"],
        workshopIds: settings.workshopIds,
        orderLineStatus: settings.type === "kitchen"
          ? ["received", "processing", "cancelled"] : null,
        hasOrderLineStatus: settings.type === "distribution"
          ? ["processing", "ready", "served"] : null,
      },
    });

    return () => {
      conn.send({ action: "unsubscribe", id: subscriptionId });
      conn.off("data", listener);
    };
  }, [conn, JSON.stringify(settings)]); // JSON.stringify to prevent double call

  return orders;
};

export default useOrders;
