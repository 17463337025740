import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Context from "./context";
import KitchenService from "./KitchenService";

export const Provider = ({ config, children }) => {
  const [context, setContext] = useState(new KitchenService(config));

  useEffect(() => {
    if (config !== context.config) {
      const service = new KitchenService(config);
      setContext(service);
    }

    return () => context.cleanup();
  }, [config]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useServiceState = () => {
  const service = useContext(Context);
  const [state, setState] = useState(service.state);

  useEffect(() => {
    setState(service.state);
    service.on("state_changed", setState);
    return () => service.off("state_changed", setState);
  }, [service]);

  return state;
};

Provider.propTypes = {
  config: PropTypes.shape({
    location: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.node,
};
