import EventEmitter from "events";
import Peer from "peerjs";

const FATAL_PEER_ERRORS = [
  "browser-incompatible",
  "invalid-id",
  "ssl-unavailable",
  "unavailable-id",
];

export default class KitchenService extends EventEmitter {
  constructor(config) {
    super();
    this.state = { initializing: true, error: null, conn: null };
    this.config = config;

    this.createPeer();

    this.peerTimerId = setInterval(
      () => {
        if (this.peer.disconnected || !this.state.conn) {
          this.retry();
        }
      },
      5 * 1000,
    );
  }

  cleanup() {
    clearInterval(this.peerTimerId);
    this.peer.destroy();
  }

  /**
   * Updates the state and emits the "state_changed" event
   * @param {Object} newState
   * @param {boolean} newState.initializing
   * @param {Object} [newState.error]
   * @param {Object} [newState.conn]
   *
   * @returns null
   */
  setState(newState) {
    this.state = newState;
    this.emit("state_changed", newState);
  }

  createPeer() {
    this.peer = new Peer({
      debug: process.env.NODE_ENV === "production" ? 0 : 3,
      host: process.env.REACT_APP_PEER_JS_HOST,
      path: "/peerjs/peer",
      secure: true,
      config: {
        iceServers: [
          {
            url: "stun:stun1.l.google.com:19302",
            urls: "stun:stun1.l.google.com:19302",
          },
          {
            url: "stun:stun2.l.google.com:19302",
            urls: "stun:stun2.l.google.com:19302",
          },
        ],
        sdpSemantics: "unified-plan",
      },
    });
    this.peer.on("open", () => this.createConnection());
    this.peer.on("error", (error) => {
      this.setState({ initializing: false, error, conn: this.state.conn });
      if (!FATAL_PEER_ERRORS.includes(error.type)) {
        this.retry();
      }
    });
  }

  retry() {
    this.setState({ initializing: true, error: null, conn: this.state.conn });
    if (this.peer.destroyed) {
      this.createPeer();
    } else if (this.peer.disconnected) {
      this.peer.reconnect();
    } else if (!this.state.conn) {
      this.createConnection();
    }
  }

  createConnection() {
    if (this.state.conn) {
      this.setState({ initializing: false, error: null, conn: this.state.conn });
      return;
    }
    const conn = this.peer.connect(`salempos-${this.config.location.id}`, {
      serialization: "json", metadata: { type: "kitchen" },
    });
    conn.on("open", () => {
      this.setState({ initializing: false, error: null, conn });
    });
    conn.on("close", () => {
      this.setState({ initializing: true, error: null, conn: null });
      this.createConnection();
    });
    conn.on("iceStateChanged", (newState) => {
      if (newState === "disconnected") {
        conn.close();
      }
    });
  }
}
