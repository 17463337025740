import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, styled as muiStyled } from "@material-ui/core/styles";

import colors from "../theme/colors";
import i18n from "../i18n";

const TYPE_LABELS = {
  kitchen: i18n.t("const:type_label.kitchen"),
  distribution: i18n.t("const:type_label.distribution"),
};

const WORKSHOPS_QUERY = gql`
  query @hasura {
    workshops {
      id
      name
    }
  }
`;

const ExitButton = styled(Button)`
  && {
    width: 100%;
    color: ${colors.white};
    background-color: ${colors.dodgerBlue};

    :hover {
      background-color: ${colors.darkBlue};
    }
  }
`;

const useStyles = makeStyles({
  appBar: {
    background: colors.dodgerBlue,
    maxHeight: "64px",
  },
  title: {
    marginLeft: "10px",
    flex: 1,
  },
  contentDialog: {
    margin: "0px",
    position: "absolute",
    top: "64px",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  marginList: {
    marginTop: "20px",
  },
});

const Loader = muiStyled(CircularProgress)({
  position: "fixed",
  top: "50%",
  left: "50%",
  color: colors.dodgerBlue,
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const SettingsDialog = ({ terminal, location, open, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [getWorkshops, { loading, data }] = useLazyQuery(WORKSHOPS_QUERY, {
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  useEffect(() => {
    if (open && terminal.type === "kitchen") getWorkshops();
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            {t("SettingDialog.Setting")}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid
        container
        className={classes.contentDialog}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={8} lg={6} container direction="row">
          <Grid item xs={7}>
            <Typography variant="h6">
              {t("SettingDialog.Type")}: {TYPE_LABELS[terminal.type]}
            </Typography>
            <Typography variant="h6">
              {t("SettingDialog.Location")}: {location.name}
            </Typography>
            {terminal.type === "kitchen" && (
              <>
                <Typography variant="h6" className={classes.marginList}>
                  {t("SettingDialog.Workshop")}:
                </Typography>
                {loading ? (
                  <Loader />
                ) : (
                  data && data.workshops.map((workshop) => (
                    <Typography key={workshop.id} variant="h6">
                      {workshop.name}
                    </Typography>
                  ))
                )}
              </>
            )}
          </Grid>
          <Grid item xs={5}>
            <ExitButton
              variant="contained"
              onClick={() => {
                localStorage.removeItem("terminal_token");
                window.location.reload();
              }}
            >
              {t("SettingDialog.Logout")}
            </ExitButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

SettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  terminal: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default SettingsDialog;
