import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const ColoredTime = styled.span`
  ${({ color }) =>
    color && css`
      color: ${color};
    `};
`;

const Timer = ({ start, color }) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    setCountdown(moment().diff(start, "seconds"));
  }, [start]);

  useEffect(() => {
    const timerId = setTimeout(() => setCountdown((d) => d + 1), 1000);
    return () => clearTimeout(timerId);
  }, [countdown]);

  const hours = Math.floor(countdown / 3600);
  const minutes = Math.floor((countdown % 3600) / 60);
  const seconds = Math.floor((countdown % 3600) % 60);

  return (
    <ColoredTime color={color && color(countdown)}>
      {hours > 0 ? `${hours}:` : ""}
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}
    </ColoredTime>
  );
};

Timer.propTypes = {
  start: PropTypes.string.isRequired,
  color: PropTypes.func.isRequired,
};

export default Timer;
