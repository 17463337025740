import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

export const NEXT_ORDER_STATUS = {
  received: "processing",
  processing: "ready",
  ready: "served",
  served: "completed",
  cancelled: "cancelled",
};

export const ORDER_STATUS_ACTION_LABELS = {
  processing: "Начать",
  ready: "Готово",
  served: "Подать",
  completed: "Подано",
  cancelled: "Отменено",
};

export const DISTRIBUTION_ORDER_STATUS_ACTION_LABELS = {
  processing: "Ожидается",
  ready: "В процессе",
  served: "Подать",
  completed: "Подано",
  cancelled: "Отменено",
};

export const ORDER_STATUS_ACTION_ICONS = {
  processing: FlashOnIcon,
  ready: CheckIcon,
  served: ThumbUpAltIcon,
  cancelled: CloseIcon,
  completed: CheckIcon,
};

export const DISTRIBUTION_ORDER_STATUS_ACTION_ICONS = {
  processing: AccessTimeIcon,
  ready: FlashOnIcon,
  served: ThumbUpAltIcon,
  cancelled: CloseIcon,
  completed: CheckIcon,
};

export const ORDER_LINE_COLORS = {
  processing: "#ffeb3b",
  ready: "#76ff03",
  served: "#29B6F6",
  cancelled: "#fb3e44",
  completed: "#e0e0e0",
};

export const ORDER_LINE_HOVER_COLORS = {
  processing: "#e2d137",
  ready: "#68ca17",
  served: "#45a6d2",
  cancelled: "#fb3e44",
  completed: "#e0e0e0",
};

export const ORDER_STATUS_ACTION_DISABLED = {
  processing: false,
  ready: false,
  served: true,
  completed: true,
  cancelled: true,
};

export const DISTRIBUTION_ORDER_STATUS_ACTION_DISABLED = {
  processing: true,
  ready: true,
  served: false,
  completed: true,
  cancelled: true,
};
