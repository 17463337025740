import { Howl } from "howler";

import kitchenAudioMp3 from "./assets/kitchen_audios.mp3";
import kitchenAudioOgg from "./assets/kitchen_audios.ogg";

const sound = new Howl({
  src: [kitchenAudioOgg, kitchenAudioMp3],
  sprite: {
    cancelOrderSound: [0, 1032.7210884353741],
    newOrderSound: [3000, 1108.662131519274],
    alertNewOrderSound: [6000, 805.827664399093],
  },
});

export default sound;
