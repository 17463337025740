import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import fscreen from "fscreen";
import { useFullscreen } from "@straw-hat/react-fullscreen";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import SettingsIcon from "@material-ui/icons/Settings";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";

import { useServiceState } from "../kitchen-service";

import colors from "../theme/colors";
import sound from "../sound";

const useStyles = makeStyles({
  appBar: {
    background: colors.dodgerBlue,
    maxHeight: "64px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  padding: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  connectionStatus: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "2px",
  },
  online: {
    color: colors.lightGreen,
  },
  offline: {
    color: colors.red,
  },
  connecting: {
    color: colors.amber,
  },
});

const getStatusClass = (serviceState) => {
  if (serviceState.initializing) {
    return "connecting";
  }
  if (serviceState.conn) {
    return "online";
  }
  return "offline";
};

const Header = ({
  location,
  showSettings,
  notificationEnabled,
  setNotificationEnabled,
}) => {
  const serviceState = useServiceState();

  const [clock, setClock] = useState(moment().format("LT"));

  const classes = useStyles();

  const fullscreenTarget = React.useRef(window.document.documentElement);
  const { isFullscreen, toggleFullscreen } = useFullscreen(fullscreenTarget);

  const isInStandaloneMode = useMemo(
    () =>
      window.matchMedia("(display-mode: standalone)").matches
      || window.navigator.standalone
      || document.referrer.includes("android-app://")
      || window.screen.height - document.documentElement.clientHeight < 40,
    [],
  );

  useEffect(() => {
    setInterval(() => setClock(moment().format("LT")), 60000);
  }, []);

  /* eslint-disable no-param-reassign */
  const handleEnableSound = () => {
    setNotificationEnabled(true);
    sound.volume(1);
    sound.loop(false);
    sound.play("newOrderSound");
  };
  /* eslint-enable no-param-reassign */

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.headerItem}>
          <Typography variant="h5" className={classes.padding}>
            {location.name}
          </Typography>
          <FiberManualRecordIcon
            className={`${classes.connectionStatus} ${classes[getStatusClass(serviceState)]}`}
          />
        </div>
        <div className={classes.headerItem}>
          <IconButton color="inherit" onClick={() => showSettings()}>
            <SettingsIcon />
          </IconButton>
          {notificationEnabled ? (
            <IconButton
              color="inherit"
              onClick={() => setNotificationEnabled(false)}
            >
              <NotificationsIcon />
            </IconButton>
          ) : (
            <IconButton color="inherit" onClick={handleEnableSound}>
              <NotificationsOffIcon />
            </IconButton>
          )}
          <Typography variant="h5" className={classes.padding}>
            {clock}
          </Typography>
          {!isInStandaloneMode && fscreen.fullscreenEnabled && (
            <IconButton color="inherit" onClick={toggleFullscreen}>
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  showSettings: PropTypes.func.isRequired,
  notificationEnabled: PropTypes.bool.isRequired,
  setNotificationEnabled: PropTypes.func.isRequired,
};

export default Header;
