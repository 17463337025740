import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { writeStorage } from "@rehooks/local-storage";

import Button from "@material-ui/core/Button";

import { useServiceState } from "kitchen-service";
import sound from "sound";

import SettingsDialog from "./components/SettingsDialog";
import Header from "./components/Header";
import Orders from "./pages/orders/Orders";

const PEERJS_ERROR_ALERT_KEY = "peerjs-alert-key";

const App = ({ terminal, location }) => {
  const { error, conn } = useServiceState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showSettings, setShowSettings] = useState(false);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  useEffect(() => {
    sound.volume(notificationEnabled ? 1 : 0);
  }, [notificationEnabled]);

  useEffect(() => {
    writeStorage("settings", {
      type: terminal.type, ...terminal.settings,
    });
  }, [terminal]);

  useEffect(() => {
    if (error && !conn) {
      const message = error.type === "peer-unavailable"
        ? "Не удалось соединиться с терминалом. Убедитесь что главный терминал (Master) включен и находиться онлайн."
        : error.message;
      enqueueSnackbar(message, {
        persist: true,
        variant: "error",
        key: PEERJS_ERROR_ALERT_KEY,
        action: (key) => (<Button onClick={() => closeSnackbar(key)}>OK</Button>),
      });
    }
    if (conn) {
      closeSnackbar(PEERJS_ERROR_ALERT_KEY);
    }
  }, [error, conn]);

  return (
    <>
      <Header
        location={location}
        showSettings={() => setShowSettings(true)}
        notificationEnabled={notificationEnabled}
        setNotificationEnabled={setNotificationEnabled}
      />
      <Orders
        type={terminal.type}
        notificationEnabled={notificationEnabled}
      />
      <SettingsDialog
        location={location}
        terminal={terminal}
        open={showSettings}
        onClose={() => setShowSettings(false)}
      />
    </>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  terminal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(["kitchen", "distribution"]).isRequired,
    settings: PropTypes.object,
  }).isRequired,
};

export default App;
